(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/templates/base/assets/javascripts/base.js') >= 0) return;  svs.modules.push('/templates/base/assets/javascripts/base.js');


var svs = svs || {};

(function(svs) {
  'use strict';

  if (svs.core.detect.os.iOS() === true) {
    svs.core.detect.orientation.on('change', function() {
      $(window).scrollTop(0);
    });
  }

  if (svs.core.detect.formfactor.desktop()) {
    setFocusOnTab();
  }

  if (svs.core.detect.feature('ft-slim-login')) {
    svs.core.module.load(
      '/cl/accountservices-react/react:login-slim',
      null,
      function() {
        if (svs.accountservices_react && svs.accountservices_react.login_slim && svs.utils.url.queryString) {
          if (svs.utils.url.queryString.valkommen) {
            svs.accountservices_react.login_slim.initLegalInfo();
          } else if (svs.utils.url.queryString.lm) {
            svs.accountservices_react.login_slim.init();
          }
        }
      },
      false
    );
  }


  function setFocusOnTab() {
    var lastKey = new Date();
    var lastClick = new Date();
    var focusClass = 'svs-keyboard-focus';
    var focusSelector = '.svs-keyboard-focus';
    var $document = $(document);

    $document.on('focusin', function(e) {
      $(focusSelector).removeClass(focusClass);
      var wasByKeyboard = lastClick < lastKey;

      if (wasByKeyboard) {
        $(e.target).addClass(focusClass);
      }
    });

    $document.on('mousedown', function() {
      lastClick = new Date();
    });

    $document.on('keydown', function() {
      lastKey = new Date();
    });
  }
})(svs);


 })(window);